export default {
  breakpoints: ['48em', '62em', '72em'], // 768 992 1152
  sizes: {
    container: 1440,
    contentTitle: 1200,
    content: 728,
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256, 512],
  radii: [10],
  fonts: {
    body:
      '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Roboto Condensed", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  fontSizes: [14, 16, 20, 24, 32, 48, 52],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.44,
    heading: 1.125,
  },
  initialColorModeName: 'light',
  colors: {
    text: '#151B26',
    textOnDark: '#fff',
    textPale: '#BDD9D7',
    background: '#F8F9F9',
    backgroundHero: '#03363D',
    backgroundHeader: '#03363D',
    backgroundFooter: '#03363D',
    backgroundCard: '#fff',
    primary: '#03363D',
    secondary: '#1DB954',
    tertiary: '#30AABC',
    muted: '#F9F5F2',
    topPostsBorder: '#17494D',
    modes: {
      dark: {
        text: '#fff',
        textOnDark: '#fff',
        textPale: '#BDD9D7',
        background: '#222029',
        backgroundHero: '#222029',
        backgroundHeader: '#222029',
        backgroundFooter: '#2E2B38',
        backgroundCard: '#2E2B38',
        primary: '#fff',
        secondary: '#1DB954',
        tertiary: '#30AABC',
        muted: '#2E2B38',
        topPostsBorder: '#30AABC',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',

      // react-sliding-pane styles
      '.slide-pane__header': {
        display: 'none',
      },
      '.slide-pane__content': {
        p: 0,
        bg: 'backgroundFooter',
      },
      '.slide-pane__overlay': {
        zIndex: 9999,
      },
      h1: {
        mt: 0,
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: [4, 6],
      },
      h2: {
        mt: 0,
        mb: [4, 4, 5],
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: [4, 5],
      },
      h3: {
        mt: 0,
        mb: [4, 4, 5],
        color: 'text',
        fontFamily: 'heading',
        lineHeight: '1.3',
        fontWeight: 'heading',
        fontSize: [3, 4],
      },
      h4: {
        mt: 0,
        mb: [4, 4, 5],
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: [2, 3],
      },
      h5: {
        mt: 0,
        mb: [4, 4, 5],
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: [1, 2],
      },
      h6: {
        mt: 0,
        mb: [4, 4, 5],
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 1,
      },
      p: {
        mt: 0,
        mb: [4, 4, 5],
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'body',
        lineHeight: ['body', '1.6'],
        fontSize: 1,
      },
      a: {
        color: 'primary',
        fontSize: 1,
      },
      ul: {
        mt: 0,
        mb: 7,
      },
      li: {
        mb: 3,
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit',
        },
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit',
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },

      figure: {
        m: 0,
        mb: [4, 4, 5],
      },
      img: {
        maxWidth: '100%',
      },
      iframe: {
        maxWidth: '100%',
      },
      hr: {
        maxWidth: '576px',
        width: '90%',
        mx: 'auto',
        borderTopWidth: '2px',
        borderTopStyle: 'solid',
        borderTopColor: 'text',
      },
      '.wpb_video_wrapper': {
        paddingTop: '56.25%',
        position: 'relative',
        width: '100%',
        iframe: {
          width: '100%',
          height: '100%',
          display: 'block',
          position: 'absolute',
          margin: '0',
          top: '0',
          left: '0',
        },
      },
      '.vc_align_center': {
        textAlign: 'center',
      },
      '.vc_btn3-container': {
        display: 'none',
      },
      '.wpb_video_widget': {
        mb: [4, 4, 5],
      },
      '.__react_component_tooltip.show': {
        opacity: 1,
      },
      '.__react_component_tooltip': {
        borderRadius: '3px',
        display: 'inline-block',
        fontSize: '13px',
        left: '-999em',
        opacity: '0',
        padding: '8px 21px',
        position: 'fixed',
        pointerEvents: 'none',
        transition: 'opacity 0.3s ease-out',
        top: '-999em',
        visibility: 'hidden',
        zIndex: '999',
      },
      // custom styled for posts
      'blockquote:not(.instagram-media):not(.instagram-media-registered)': {
        position: 'relative',
        padding: ['24px 0 0', '24px 16px 0'],
        margin: '16px',
        fontSize: '18px',
        fontStyle: 'italic',
        fontFamily: '"Open Sans", sans-serif',
        border: 'none',
        '&:before': {
          content: '"”"',
          position: 'absolute',
          left: '-20px',
          top: '40px',
          fontFamily: 'serif',
          fontSize: '160px',
          fontWeight: 'bold',
          lineHeight: '0px',
          color: '#1DA1F2',
          opacity: '0.5',
          width: '100%',
        },
      },
      '.border-block': {
        margin: '20px 0',
        padding: '20px',
        borderRadius: '10px',
        '&--red': {
          border: '4px solid #E81717',
        },
        '&--blue': {
          border: '4px solid #1DA1F2',
        },
        '&--green': {
          border: '4px solid #1DB954',
        },
        '&--violet': {
          border: '4px solid #581DB9',
        },
      },

      '.filled-block': {
        margin: '20px 0',
        padding: '20px',
        color: '#ffffff',
        borderRadius: '10px',
        '&--red': {
          backgroundColor: '#E81717',
        },
        '&--blue': {
          backgroundColor: '#1DA1F2',
        },
        '&--green': {
          backgroundColor: '#1DB954',
        },
        '&--violet': {
          backgroundColor: '#581DB9',
        },
      },
    },
  },
  // VARIANTS
  buttons: {
    link: {
      display: 'flex',
      alignItems: 'center',
      p: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: 'none',
      boxShadow: 'none',
    },
    green: {
      display: 'flex',
      alignItems: 'center',
      mx: 'auto',
      p: 0,
      px: 7,
      py: 3,
      border: 'none',
      borderRadius: '100px',
      boxShadow: 'none',
      bg: 'secondary',
      color: 'textOnDark',
      fontWeight: 'bold',
      fontFamily: 'body',
      letterSpacing: '.05rem',
      textTransform: 'uppercase',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  boxes: {
    container: {
      maxWidth: 'container',
      mx: 'auto',
      px: [4, 4, 9],
    },
  },
  links: {
    textOnDark: {
      color: 'textOnDark',
      textDecoration: 'none',
    },
  },
}
