import React from 'react'
import { PostsViewsContextProvider } from 'src/context/postsViewsContext'
import { PostsToShowContextProvider } from 'src/context/postsToShowContext'

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const wrapRootElement = ({ element }) => (
  <PostsToShowContextProvider>
    <PostsViewsContextProvider>{element}</PostsViewsContextProvider>
  </PostsToShowContextProvider>
)
