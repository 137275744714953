import React, { createContext, useEffect, useState } from 'react'
import fetch from 'node-fetch'

export const PostsViewsContext = createContext({ getPostViews: () => {} })

export const PostsViewsContextProvider = ({ children }) => {
  const [postsViews, setPostViews] = useState([])
  useEffect(() => {
    fetch(`${process.env.GATSBY_WORDPRESS_URL}wp-json/api/tbnt/get-posts-views`)
      .then((res) => res.json())
      .then((posts) => setPostViews(posts))
  }, [])

  return (
    <PostsViewsContext.Provider
      value={{
        getPostViews: (id) => {
          const post = postsViews.find((post) => post.id === id)
          return post?.viewCount
        },
        postsViews,
      }}
    >
      {children}
    </PostsViewsContext.Provider>
  )
}
