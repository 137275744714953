import React, { createContext, useState } from 'react'

export const PostsToShowContext = createContext({})

export const PostsToShowContextProvider = ({ children }) => {
  const [pages, setPages] = useState({})

  const setPostsToShow = (pid, postsToShow) => {
    pages[pid] = postsToShow
    setPages({ ...pages })
  }

  const getPostsToShow = (pid) => {
    return pages[pid]
  }

  return (
    <PostsToShowContext.Provider value={{ setPostsToShow, getPostsToShow }}>
      {children}
    </PostsToShowContext.Provider>
  )
}
